import { Swiper, SwiperSlide } from 'swiper/react';
import WrapperContent from '@components/Layout/WrapperContent';
import Link from 'next/link';
import { TimeLine } from '@/types/types';
import Titulo from '@/components/Layout/Title';
import * as S from './styles';
import { mglGray } from '@/styles/designSystemColors';

export interface RankingProps {
  featuredRankingData: TimeLine[];
}

export default function FeaturedPopularNews({ featuredRankingData }: RankingProps) {
  const pagination = {
    clickable: true,
  };

  return (
    <WrapperContent backgroundColor={mglGray.mglGray50} paddingOnBottom>
      <Titulo texto="Mais Lidas" urlRedirect="/mais-lidas/" color="black" showChip alignRight />
      <S.PopularNewsContainer>
        <Swiper
          pagination={pagination}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              slidesPerGroup: 1,
              spaceBetween: 16,
            },
            768: {
              slidesPerView: 1,
              slidesPerGroup: 1,
              spaceBetween: 16,
            },
            1024: {
              slidesPerView: 1,
              slidesPerGroup: 1,
              spaceBetween: 16,
            },
          }}>
          <SwiperSlide key={1}>
            <S.ContainerStack>
              {featuredRankingData.slice(0, 3).map((item, index) => (
                <S.ItemList key={item.url}>
                  <Link href={item.url} passHref>
                    <S.MostReadItem title={item.titulo}>
                      <S.Count>
                        <span>{index + 1}</span>
                      </S.Count>
                      <span>{item.titulo}</span>
                    </S.MostReadItem>
                  </Link>
                </S.ItemList>
              ))}
            </S.ContainerStack>
          </SwiperSlide>

          <SwiperSlide key={2}>
            <S.ContainerStack>
              {featuredRankingData.slice(3, 6).map((item, index) => (
                <S.ItemList key={item.url}>
                  <Link href={item.url} passHref>
                    <S.MostReadItem title={item.titulo}>
                      <S.Count>
                        <span>{index + 1 * 4}</span>
                      </S.Count>
                      <span>{item.titulo}</span>
                    </S.MostReadItem>
                  </Link>
                </S.ItemList>
              ))}
            </S.ContainerStack>
          </SwiperSlide>

          <SwiperSlide key={3}>
            <S.ContainerStack>
              {featuredRankingData.slice(6, 9).map((item, index) => (
                <S.ItemList key={item.url}>
                  <Link href={item.url} passHref>
                    <S.MostReadItem title={item.titulo}>
                      <S.Count>
                        <span>{index + 1 * 7}</span>
                      </S.Count>
                      <span>{item.titulo}</span>
                    </S.MostReadItem>
                  </Link>
                </S.ItemList>
              ))}
            </S.ContainerStack>
          </SwiperSlide>
        </Swiper>
      </S.PopularNewsContainer>
    </WrapperContent>
  );
}
