import WrapperContent from '@components/Layout/WrapperContent';
import { TimeLine } from '@/types/types';
import NewsTimeLine, { PropsTitle, PropsAutor } from '@/components/Layout/NewsTimeLine';
import Titulo from '@/components/Layout/Title';
import { mglBlue, mglGray } from '@/styles/designSystemColors';

const PropAutor: PropsAutor = {
  text: true,
  image: true,
  imgInImage: true,
  imgRounded: true,
};

export interface FeaturedColumnist {
  featuredColumnistData: TimeLine[];
}

export default function FeaturedColuna({ featuredColumnistData }: FeaturedColumnist) {
  const PropTitle: PropsTitle = {
    color: 'darkText',
    altColorInHover: true,
    colorHover: mglBlue.mglBlue400,
  };

  return (
    <WrapperContent backgroundColor={mglGray.mglGray200} paddingOnBottom>
      <Titulo texto="Colunas" urlRedirect="/colunas/" color="black" showChip alignRight />
      <div className="grid grid-cols-1 grid-rows-2 gap-5 [grid-auto-columns:1fr] tablet:grid-cols-2 desktop:grid-cols-4 desktop:grid-rows-1">
        {featuredColumnistData.map(n => (
          <NewsTimeLine news={n} key={n.url} propsTitle={PropTitle} autor propsAutor={PropAutor} />
        ))}
      </div>
    </WrapperContent>
  );
}
