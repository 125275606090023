import FeaturedNews from '@components/Home/FeaturedNews';
import VideoSliders from '@components/Home/FeaturedVideos';
import dynamic from 'next/dynamic';
import { GetServerSideProps } from 'next';
import LazyLoad from 'react-lazyload';
import { api, mimir, site } from '@/services/api';
import { AdContainer } from '@/components/Layout/ItemListLastNews/styles';
import { FeaturedNewsType, FeaturedNewsApi, NavBarContentData, FeaturedDealsHome, TimeLine, FeaturedDeal } from '@/shared/types/home.types';
import Layout from '@/components/Layout';
import getConcurrentPromises from '@/utils/getConcurrentPromises';
import OfferBanner from '@/components/Internals/OfferBanner';
import FeaturedProducts from '@/components/Home/FeaturedProducts';
import FeaturedDeals from '@/components/Home/FeaturedDeals';
import FeaturedProductsAnalysis from '@/components/Home/FeaturedProductsAnalysis';
import AdsGeneratorMemo from '@/components/Ads/Google/AdsTypeGeneratorsMemo';
import FeaturedCategories from '@/components/Home/FeaturedCategories';
import FeaturedCategory from '@/components/Home/FeaturedCategory';
import FeaturedPopularNews from '@/components/Home/FeaturedPopularNews';
import FeaturedColuna from '@/components/Home/FeaturedColuna';
import FeaturedEditorialCategory from '@/components/Home/FeaturedEditorialCategory';
import { mglGray } from '@/styles/designSystemColors';
import useBreakpoint from '@/hooks/useBreakpoint';

export default function Home({ homeData, navBarData, tagsData, segmentacoes, schema = null }: FeaturedNewsType): JSX.Element {
  const AdsTypeGenerators = dynamic(() => import('@/components/Ads/Google/AdsTypeGenerators'), { ssr: false });
  const FeaturedLastNews = dynamic(() => import('@/components/Home/FeaturedLastNews'), { ssr: false });
  const FeaturedProductExclusive = dynamic(() => import('@/components/Home/FeaturedProductExclusive'), { ssr: false });
  const shouldRender = (key: keyof typeof homeData) => {
    const value = homeData[key];
    return Array.isArray(value) && value.length > 0;
  };

  const [isMobile] = useBreakpoint('phone');

  return (
    <Layout layoutData={{ segmentacoes, tagsData, navBarData, schema }} headingTag="h1">
      <OfferBanner isOnHome />
      <FeaturedNews featuredNewsData={homeData.vitrine} />
      <VideoSliders featuredVideosData={homeData.videos} />
      <AdContainer noPadding>
        <AdsTypeGenerators type="billboard/main/home" />
        <AdsTypeGenerators type="rectangle/main/home" />
      </AdContainer>
      <LazyLoad height={1}>
        <FeaturedProductExclusive />
      </LazyLoad>
      <FeaturedProducts featuredProductsData={homeData.produtos} />
      {shouldRender('topOffers') && <FeaturedDeals deals={homeData.topOffers} />}
      {shouldRender('manualAnalysis') && (
        <FeaturedProductsAnalysis featuredProductsAnalysis={homeData.manualAnalysis} paddingOnBottom bgColor={mglGray.mglGray200} isOnHeader />
      )}
      <AdContainer>
        <AdsGeneratorMemo type="billboard/main/home" />
        <AdsGeneratorMemo type="rectangle/main/home" />
      </AdContainer>
      <FeaturedCategories featuredCategoriesData={homeData.categoria3} />
      {isMobile && (
        <AdContainer>
          <AdsGeneratorMemo type="rectangle/main/home" />
        </AdContainer>
      )}
      <FeaturedCategory featuredCategory={homeData.categoria1} />
      <AdContainer>
        <AdsGeneratorMemo type="billboard/main/home" />
        <AdsGeneratorMemo type="rectangle/main/home" />
      </AdContainer>
      {shouldRender('mostRead') && <FeaturedPopularNews featuredRankingData={homeData.mostRead} />}
      <FeaturedColuna featuredColumnistData={homeData.colunistas} />
      <AdContainer>
        <AdsGeneratorMemo type="billboard/main/home" />
        <AdsGeneratorMemo type="rectangle/main/home" />
      </AdContainer>
      {shouldRender('ctup') && <FeaturedEditorialCategory variant="ctup" timeline={homeData.ctup.slice(0, 6)} />}
      {shouldRender('ctEletro') && <FeaturedEditorialCategory variant="eletro" timeline={homeData.ctEletro.slice(0, 6)} />}
      {shouldRender('ctauto') && <FeaturedEditorialCategory variant="ctauto" timeline={homeData.ctauto.slice(0, 6)} />}
      <AdContainer>
        <AdsGeneratorMemo type="billboard/main/home" />
        <AdsGeneratorMemo type="rectangle/main/home" />
      </AdContainer>
      <LazyLoad height={1}>
        <FeaturedLastNews rootPage="/" textTitle="Últimas Notícias" route="" routeType="ultimas" useServerSide={false} />
      </LazyLoad>
    </Layout>
  );
}

export const getServerSideProps: GetServerSideProps = async context => {
  const { host } = context.req.headers;

  const mainReq = { promise: site.get<FeaturedNewsApi>(`/`), link: `/` };
  const responseMenuReq = { promise: api.get<NavBarContentData>(`/componentes/menu/`), link: `/componentes/menu/` };
  const ctupReq = {
    promise: mimir.get<Pick<FeaturedNewsApi, 'data'>>(`/timelines/ultimas/id/155778?merge=155790`),
    link: `/timelines/ultimas/id/155778?merge=155790`,
  };
  const ctEletroReq = { promise: mimir.get<Pick<FeaturedNewsApi, 'data'>>(`/timelines/ultimas/categoria/eletro`), link: `/timelines/ultimas/categoria/eletro` };
  const autoReq = { promise: mimir.get<Pick<FeaturedNewsApi, 'data'>>(`/timelines/ultimas/categoria/ctauto`), link: `/timelines/ultimas/categoria/ctauto` };
  const mostReadReq = { promise: mimir.get<Pick<FeaturedNewsApi, 'data'>>(`/timelines/mais/lidas/1-dia`), link: `/timelines/mais/lidas/1-dia` };
  const topOffersReq = { promise: api.get<FeaturedDealsHome>('/ofertas/top10/'), link: '/ofertas/top10/' };
  const manualAnalysisReq = { promise: api.get<Pick<FeaturedNewsApi, 'data'>>('/home/analises/'), link: '/home/analises/' };

  const { promiseData, data, statusCode } = await getConcurrentPromises({
    mainPromise: mainReq,
    navBarPromise: responseMenuReq,
    otherPromises: [ctupReq, ctEletroReq, autoReq, mostReadReq, topOffersReq, manualAnalysisReq],
    host,
  });

  if (data.redirect || data.notFound) return data;

  const homeData = data.data;
  const schema = data?.schema;

  const navBarData: NavBarContentData = promiseData[responseMenuReq.link].data.data;
  const ctupData: { ctup: TimeLine[] } = { ctup: promiseData[ctupReq.link]?.data?.data?.timeline?.itens };
  const ctEletroData: { ctEletro: TimeLine[] } = { ctEletro: promiseData[ctEletroReq.link]?.data?.data?.timeline?.itens };
  const autoData: { ctauto: TimeLine[] } = { ctauto: promiseData[autoReq.link]?.data?.data?.timeline?.itens };
  const mostReadData: { mostRead: TimeLine[] } = { mostRead: promiseData[mostReadReq.link]?.data?.data?.timeline?.itens };
  const topOffersData: { topOffers: FeaturedDeal[] } = { topOffers: promiseData[topOffersReq.link]?.data?.data?.itens };
  const manualAnalysisData: { manualAnalysis: TimeLine[] } = { manualAnalysis: promiseData[manualAnalysisReq.link]?.data?.data?.itens };

  Object.assign(homeData, ctupData, ctEletroData, autoData, mostReadData, topOffersData, manualAnalysisData);

  const tagsData = data.ssr;
  const { segmentacoes } = data;

  context.res.setHeader('Cache-Control', data.headers['Cache-control']);
  context.res.setHeader('Expires', data.headers.Expires);
  context.res.statusCode = statusCode;

  return {
    props: {
      tagsData,
      homeData,
      schema,
      navBarData,
      segmentacoes,
    },
  };
};
