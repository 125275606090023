import { ComponentPropsWithoutRef } from 'react';

export const PopularNewsContainer = ({ children, ...rest }: ComponentPropsWithoutRef<'div'>) => {
  return (
    <div
      className="bottom-0 text-right [&_.swiper-pagination-bullet]:[&_.swiper-pagination]:h-2 [&_.swiper-pagination-bullet]:[&_.swiper-pagination]:w-2 [&_.swiper-pagination-bullet]:[&_.swiper-pagination]:rounded-2xl [&_.swiper-pagination-bullet]:[&_.swiper-pagination]:bg-black/20 [&_.swiper-pagination-bullet]:[&_.swiper-pagination]:[transition:background-color_0.3s_0s,_width_0.2s_0.3s] [&_span.swiper-pagination-bullet-active]:[&_.swiper-pagination]:w-[26px] [&_span.swiper-pagination-bullet-active]:[&_.swiper-pagination]:bg-mglBlue400 [&_span.swiper-pagination-bullet-active]:[&_.swiper-pagination]:[transition:background-color_0.3s_0s,_width_0.2s_0.3s]"
      {...rest}>
      {children}
    </div>
  );
};

export const ContainerStack = ({ children, ...rest }: ComponentPropsWithoutRef<'div'>) => {
  return (
    <div className="flex w-full flex-col tablet:flex-row" {...rest}>
      {children}
    </div>
  );
};

export const ItemList = ({ children, ...rest }: ComponentPropsWithoutRef<'article'>) => {
  return (
    <article className="mx-0 my-4 flex w-full h-auto flex-col items-center px-1 py-0" {...rest}>
      {children}
    </article>
  );
};

export const MostReadItem = ({ children, ...rest }: ComponentPropsWithoutRef<'a'>) => {
  return (
    <a
      className="inline-flex h-full w-full cursor-pointer items-center text-left font-light text-mglGray900 no-underline transition-colors duration-300 ease-ease hover:text-mglBlue400 [&>div]:hover:border-mglGray300"
      {...rest}>
      {children}
    </a>
  );
};

export const Count = ({ children, ...rest }: ComponentPropsWithoutRef<'div'>) => {
  return (
    <div
      className="[&_span]:border-inherit [&_span]:text-inherit mr-4 text-mglGray900 transition-[border-color] duration-300 ease-ease [&_span]:block [&_span]:h-10 [&_span]:w-10 [&_span]:rounded-half [&_span]:border [&_span]:border-solid [&_span]:border-black/10 [&_span]:text-center [&_span]:text-2xl [&_span]:font-medium"
      {...rest}>
      {children}
    </div>
  );
};
